.MuiPopover-paper::-webkit-scrollbar {
  width: 5px;
}

.MuiPopover-paper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.MuiPopover-paper::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  outline: 1px solid slategrey;
}

.zsiq_floatmain {
  display: none !important;
}

.zsiq_floatmain.visible {
  display: block !important;
  height: 60px !important;
  z-index: 1000 !important;
  margin-right: 15px;
}
@media (min-width: 645px) {
  .zls-sptwndw.zsiq-newtheme {
    z-index: 1000 !important;
  }
}
@media (max-width: 645px) {
  .hide-chatbot .zsiq_floatmain.visible{
    display: none !important;
  }
  div#titlediv {
    display: none;
  }
}
#main-scrollbar .ps__rail-x, #main-scrollbar .ps__rail-y {
  opacity: 0.6;
}
.hide-promotions .promotion-item{
  display: none;
}

.cnx-main-container{
  border-radius: 10px;
}